import React from 'react';
import { Link } from 'react-router-dom';

function Dat() {
  return (
    <div className="container italic">
      <Link to="/" className="right-text">
        zurück
      </Link>
      
      <div className="datenschutz-content">
        <h1>Datenschutzerklärung</h1>
        
        <h2>1. Datenschutz auf einen Blick</h2>
        
        <h3>Allgemeine Hinweise</h3>
        <p>
          Der Schutz Ihrer persönlichen Daten ist uns bei VIERVORNEUN GmbH sehr wichtig. 
          Diese Datenschutzerklärung informiert Sie umfassend über die Datenverarbeitung 
          auf unserer Website.
        </p>

        <h2>2. Verantwortliche Stelle</h2>
        <div>
          <p>VIERVORNEUN GmbH</p>
          <p>Friedberger Landstr. 94, 60316 Frankfurt am Main</p>
          <p>Vertreten durch: Quynh Anh Nguyen & Thi Bich Ngoc Nguyen</p>
          <p>E-Mail: info@viervorneun.com</p>
          <p>Telefon: +49 (0) 69 12345678</p>
        </div>

        <h2>3. Datenerfassung und Datenverarbeitung</h2>
        
        <h3>Umfang der Datenverarbeitung</h3>
        <p>
          Wir erheben und speichern grundsätzlich keine personenbezogenen Daten von 
          Besuchern unserer Website. Die Website dient ausschließlich Informationszwecken.
        </p>

        <h2>4. Technische Aspekte</h2>

        <h3>Serverdaten</h3>
        <p>
          Aus technischen Gründen werden bei jedem Websiteaufruf folgende Nutzungsdaten 
          temporär in Protokolldateien gespeichert:
        </p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <p>
          Diese Daten werden ausschließlich zur technischen Sicherstellung des 
          Websitebetriebs verwendet und nicht mit anderen Daten zusammengeführt.
        </p>

        <h3>Cookies</h3>
        <p>
          Unsere Website verwendet keine Cookies zur Datensammlung oder Nutzerverfolgung. 
          Wir setzen keine Tracking-Technologien ein.
        </p>

        <h3>Nutzung von Google Fonts</h3>
        <p>
          Auf unserer Website verwenden wir Google Fonts. Diese Schriftarten werden 
          vollständig lokal auf unserem eigenen Server gespeichert und gehostet. 
          Durch diese lokale Speicherung wird keine Verbindung zu Google-Servern 
          hergestellt und es erfolgt keine Datenübertragung an Dritte.
        </p>
        <p>
          Weitere Informationen: 
          <a 
            href="https://policies.google.com/privacy" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Google Datenschutzrichtlinien
          </a>
        </p>

        <h3>SSL-Verschlüsselung</h3>
        <p>
          Unsere Website nutzt aus Sicherheitsgründen eine SSL-Verschlüsselung. 
          Sie erkennen dies am "https://" in der Adressleiste und am Schloss-Symbol 
          in Ihrem Browser.
        </p>

        <h2>5. Ihre Rechte</h2>
        
        <h3>Datenschutzrechte</h3>
        <p>
          Sollten wider Erwarten personenbezogene Daten verarbeitet werden, 
          haben Sie folgende Rechte:
        </p>
        <ul>
          <li>Recht auf Auskunft über gespeicherte Daten</li>
          <li>Recht auf Berichtigung unrichtiger Daten</li>
          <li>Recht auf Löschung Ihrer Daten</li>
          <li>Recht auf Einschränkung der Datenverarbeitung</li>
        </ul>

        <h3>Kontakt bei Datenschutzfragen</h3>
        <p>
          Bei Fragen zum Datenschutz oder zur Datenverarbeitung kontaktieren 
          Sie uns bitte unter: info@viervorneun.com
        </p>

        <h2>6. Rechtliche Grundlagen</h2>
        <p>
          Diese Datenschutzerklärung basiert auf den Bestimmungen der 
          Datenschutz-Grundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG).
        </p>

        <h2>7. Änderungsvorbehalt</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung zu aktualisieren. 
          Die jeweils aktuelle Version finden Sie auf dieser Website.
        </p>

        <p>
          Stand der Datenschutzerklärung: November 2024
        </p>
      </div>
    </div>
  );
}

export default Dat;
